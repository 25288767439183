<template>
    <ModalWrapper ref="modal" custom-close transparent @overlay-click="close" @close-modal="close">
        <div class="modal-window">
            <div class="modal-window__close" @click="close">
                <AppIcon icon="close"/>
            </div>
            <div class="modal-window__header">
                <AppIcon icon="filter"/>
                {{ $t("components.common.filter") }}
            </div>
            <div class="modal-window__body application-input-theme-login">
                <div class="application-input-wrap">
                    <div class="application-input-title">{{ $t("components.common.date") }}</div>
                    <div class="flex-grow-1 d-flex justify-content-center align-items-center mb-2-a">
                        <div class="flex-grow-1">
                            <AppDatePicker :error="isError" v-model="model.dateFrom" ref="input" size="large" default-value=""/>
                        </div>
                        <div class="mr-16 ml-16">—</div>
                        <div class="flex-grow-1">
                            <AppDatePicker :error="isError" v-model="model.dateTo" size="large" default-value=""/>
                        </div>
                    </div>
                    <div v-if="isError" class="application-input-error">{{ $t("components.common.pleaseSelectAPeriodOfOneMonth") }}</div>
                </div>
                <div class="mb-13-a"></div>
                <div class="d-flex justify-content-end">
                    <AppButton class="mr-auto" type="button" square theme="white-outline" @click="reset" tabindex="-1">
                        <AppIcon icon="delete"/>
                    </AppButton>
                    <AppButton class="mr-16" type="button" theme="gray" @click="close" tabindex="-1">
                        {{ $t("common.cancel") }}
                    </AppButton>
                    <AppButton @click="submit" :disabled="isError" type="button">
                        {{ $t("components.common.apply") }}
                    </AppButton>
                </div>
            </div>
        </div>
    </ModalWrapper>
</template>
<script>
import AppIcon from "../partials/AppIcon";
import AppButton from "../partials/AppButton";
import ModalWrapper from "../partials/ModalWrapper";
import AppDatePicker from "../partials/AppDatePicker";

const endStartDelta = 3888000000; // 1.5 месяца

export default {
    name: 'MonitoringFilterModal',
    components: {AppDatePicker, ModalWrapper, AppButton, AppIcon},
    props: {
        value: Object,
    },
    data() {
        return {
            model: {
                dateFrom: '',
                dateTo: ''
            },
            isDirty: false,
        }
    },
    computed: {
        isError() {
            return Math.abs(new Date(this.model.dateTo) - new Date(this.model.dateFrom)) > endStartDelta || this.isDirty && this.noDates();
        }
    },
    created() {
        this.model = {...this.value};
    },
    methods: {
        noDates() {
            const start = +!!this.model.dateFrom
            const end = +!!this.model.dateTo;
            return Math.abs(start - end) === 1;
        },
        close() {
            this.$emit('close');
        },
        submit() {
            if(this.noDates()) {
                this.isDirty = true;
            } else {
                let dateFrom = this.model.dateFrom;
                let dateTo = this.model.dateTo;
                let dTemp;
                if(dateFrom > dateTo) {
                    dTemp = dateFrom;
                    dateFrom = dateTo;
                    dateTo = dTemp;
                }
                this.$emit('input', {
                    dateFrom,
                    dateTo
                });
                this.$emit('submit');
            }
        },
        reset() {
            this.isDirty = false;
            this.model = {
                dateFrom: '',
                dateTo: ''
            };
        },
        closeModal() {
            this.$refs.modal.close();
        }
    }
}
</script>
